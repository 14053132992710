import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%', // Make card take full width on small screens
    },
  },
  cardMedia: {
    paddingTop: '100%', // 1:1 aspect ratio for square image
    width: '100%',
  },
  cardContent: {
    textAlign: 'center',
  },
  titleText: {
    textAlign: 'center',
    width: '100%',
    fontSize: '1.25rem', // Larger font on small screens
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  nameText: {
    textAlign: 'center',
    width: '100%',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
}));

export default function President() {
  const classes = useStyles();
  const [presidents, setPresidents] = useState([]);

  useEffect(() => {
    axios
      .get('https://common.chicagoariranglionsclub.com/api/presidents')
      .then((response) => setPresidents(response.data))
      .catch((error) => console.error('Error fetching presidents:', error));
  }, []);

  return (
    <Grid container spacing={1} className={classes.cardGrid}>
      {presidents.map((president) => (
        <Grid item key={president.id} xs={12} sm={6} md={3} lg={2}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h6" component="h2" className={classes.titleText}>
                {president.title}
              </Typography>
            </CardContent>
            <CardMedia
              className={classes.cardMedia}
              image={president.imgUrl}
              title={president.name}
            />
            <CardContent className={classes.cardContent}>
              <Typography variant="body1" component="p" className={classes.nameText}>
                {president.name}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

