import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  cardGrid: {
    height: '50vh',
  },
}));

export default function ContactPage() {
  const styles = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
	  <div className={styles.heroContent}>
          <Container maxWidth="lg">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdD-z-cuy2BNgm7G96rxzvqP-BxmAUZgutbOnCpLZd1BFF39Q/viewform?embedded=true" width="100%" height="1000" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
	       </Container>
	  </div>
      </main>
    </React.Fragment>
  );
}

