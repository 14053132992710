import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Post from 'components/ui/post'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
}));

export default function EventsPage() {
  const styles = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
	  <div>
      <Container maxWidth="md">
        <Post filename="donate/2023.md"/>
	    </Container>
	  </div>
      </main>
    </React.Fragment>
  );
}

