// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.markdown {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .markdown h1, .markdown h2, .markdown h3 {
    margin-top: 20px;
  }
  
  .markdown p {
    line-height: 1.6;
    font-size: 1.2rem;
  }
  
  .markdown a {
    color: blue;
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ui/post.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,0BAA0B;EAC5B","sourcesContent":[".markdown {\n    padding: 20px;\n    font-family: Arial, sans-serif;\n  }\n  \n  .markdown h1, .markdown h2, .markdown h3 {\n    margin-top: 20px;\n  }\n  \n  .markdown p {\n    line-height: 1.6;\n    font-size: 1.2rem;\n  }\n  \n  .markdown a {\n    color: blue;\n    text-decoration: underline;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
