import React, { useState } from 'react';
import { Typography, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, useMediaQuery, useTheme } from '@material-ui/core';
import { commonServiceAPI } from "api/service_api";

const RSVPSearch = () => {
  const theme = useTheme();
  const isSmallScreen = true;

  const [email, setEmail] = useState('');
  const [names, setNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateEmail = (email) => {
    // Regular expression for validating email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const fetchNames = async () => {
    // Validate email format
    if (!validateEmail(email)) {
      setError('Invalid email format');
      return;
    }

    setLoading(true);
    try {
      const response = await commonServiceAPI.post('/searchRSVP', { email });
      setNames(response.data);
      setLoading(false);
      setOpen(true); // Open the dialog when names are fetched
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (

    <div style={{ textAlign: isSmallScreen ? 'center' : 'left', marginTop: 50 }}>
      <Typography variant="h3" gutterBottom>
        Search RSVP by Email
      </Typography>
      <Typography align='left'>
        * Offline submission will not be searched.
      </Typography>
      <TextField
        label="Email"
        variant="outlined"
        value={email}
        onChange={handleEmailChange}
        style={{ marginBottom: 10 }}
        fullWidth={isSmallScreen}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={fetchNames}
        disabled={loading || !email}
        style={{ marginBottom: 10 }}
        fullWidth={isSmallScreen}
      >
        {loading ? 'Loading...' : 'Search'}
      </Button>
      {error && <p>Error: {error}</p>}
      <Dialog open={open} onClose={handleClose} fullWidth={isSmallScreen} maxWidth="sm">
        <DialogTitle>Names</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Here are the attendee names registered online:
          </DialogContentText>
          <ul>
            {names.length > 0 ? (
              names.map((name, index) => (
                <li key={index}>{name}</li>
              ))
            ) : (
              <li>No RSVPs were found associated with the provided email</li>
            )}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RSVPSearch;
