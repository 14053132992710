import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Post from 'components/ui/post'

import { useLocation } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
}));

export default function EventsPage() {
  const styles = useStyles();

  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let event_id = params.get("id");
  
  const event_url = "event/"+ event_id + ".md";
  //console.log(event_url);
  //event_url
  return (
    <React.Fragment>
      <CssBaseline />
      <main>
	  <div>
      <Container maxWidth="md">
        <Post filename={event_url}/>
	    </Container>
	  </div>
      </main>
    </React.Fragment>
  );
}

