// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Quantity Dropdown Styling */
.ui.menu > div {
    width: auto !important;
    min-width: auto !important;
}

#quantity-dropdown > .text {
    font-weight: bold !important;
}

#quantity-dropdown > .icon {
    margin-left: 5px !important;
}

#quantity-dropdown > .menu > div {
    padding: 5px 15px 5px 17px !important;
}

/*****************************/

`, "",{"version":3,"sources":["webpack://./src/styles/semanticUI/commonStyles.css"],"names":[],"mappings":";AACA,8BAA8B;AAC9B;IACI,sBAAsB;IACtB,0BAA0B;AAC9B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,qCAAqC;AACzC;;AAEA,8BAA8B","sourcesContent":["\n/* Quantity Dropdown Styling */\n.ui.menu > div {\n    width: auto !important;\n    min-width: auto !important;\n}\n\n#quantity-dropdown > .text {\n    font-weight: bold !important;\n}\n\n#quantity-dropdown > .icon {\n    margin-left: 5px !important;\n}\n\n#quantity-dropdown > .menu > div {\n    padding: 5px 15px 5px 17px !important;\n}\n\n/*****************************/\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
