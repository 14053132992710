import React, { useState } from 'react';
import { TextField, Button, FormControlLabel, Checkbox, Typography, Container, Grid, makeStyles, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { commonServiceAPI } from "api/service_api";
import RSVPSearch from './RSVPSearch';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const AttendeeField = ({ attendee, index, onAttendeeChange, onCheckboxChange, onRemoveAttendee }) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label={`Attendee ${index + 1} Name`}
          value={attendee.name}
          onChange={(e) => onAttendeeChange(index, 'name', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label={`Attendee ${index + 1} Year of Birth`}
          value={attendee.yearOfBirth}
          onChange={(e) => onAttendeeChange(index, 'yearOfBirth', e.target.value)}
        />
      </Grid>
      <Grid item xs={6} sm={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={attendee.isAdoptee}
              onChange={() => onCheckboxChange(index)}
              color="primary"
            />
          }
          label="Is Adoptee?"
        />
      </Grid>
      <Grid item xs={6} sm={2} container justify="flex-end">
  <Button
    variant="contained"
    color="secondary"
    onClick={() => onRemoveAttendee(index)}
  >
    Remove
  </Button>
</Grid>

    </Grid>
  );
};

const RSVPForm = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    attendees: [{ name: '', yearOfBirth: '', isAdoptee: false }],
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  const handleAttendeeChange = (index, key, value) => {
    const updatedAttendees = [...formData.attendees];
    updatedAttendees[index][key] = value;
    setFormData({ ...formData, attendees: updatedAttendees });
  };

  const handleCheckboxChange = (index) => {
    const updatedAttendees = [...formData.attendees];
    updatedAttendees[index].isAdoptee = !updatedAttendees[index].isAdoptee;
    setFormData({ ...formData, attendees: updatedAttendees });
  };

  const handleAddAttendee = () => {
    setFormData({
      ...formData,
      attendees: [...formData.attendees, { name: '', yearOfBirth: '', isAdoptee: false }],
    });
  };

  const handleRemoveAttendee = (index) => {
    const updatedAttendees = [...formData.attendees];
    updatedAttendees.splice(index, 1);
    setFormData({ ...formData, attendees: updatedAttendees });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, email, address, attendees } = formData;

    if (!name || !email || !address || attendees.some(a => !a.name || !a.yearOfBirth)) {
      setDialogTitle('Error');
      setDialogMessage('Please fill in all the required fields for each attendee.');
      setOpenDialog(true);
      return;
    }

    // Year Of Birth Validation
    if (attendees.some(a => isNaN(a.yearOfBirth) || (a.yearOfBirth.trim().length != 4))) {
      setDialogTitle('Error');
      setDialogMessage('Please write 4 digits on the Year of Birth');
      setOpenDialog(true);
      return;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.trim())) {
      setDialogTitle('Error');
      setDialogMessage('Please enter a valid email address.');
      setOpenDialog(true);
      return;
    }

    let rsvpDto = formData.attendees.map(attendee => ({
      name: formData.name.trim(),
      email: formData.email.trim(),
      address: formData.address.trim(),
      attendeeName: attendee.name.trim(),
      attendeeYearOfBirth: attendee.yearOfBirth.trim(),
      attendeeIsAdoptee: attendee.isAdoptee
    }));

    if(rsvpDto.length == 0){
      rsvpDto = [{
        name: formData.name.trim(),
        email: formData.email.trim(),
        address: formData.address.trim(),
        attendeeName: null,
        attendeeYearOfBirth: 0,
        attendeeIsAdoptee: false
      }]
    }


    try {
      const response = await commonServiceAPI.post('/saveRSVP', rsvpDto);
      setDialogTitle('Success');
      setDialogMessage(response.data);
      setOpenDialog(true);
    } catch (err) {
      setDialogTitle('Error');
      setDialogMessage('Failed to submit the form. Please try again later.');
      setOpenDialog(true);
      console.error('Error submitting form:', err);
    }
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <Typography variant="h3" gutterBottom align='center'>
        2024 Picnic RSVP Form
      </Typography>
      <Typography>
        * Submitting with the same email will overwrite any previous submission.
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Address"
          value={formData.address}
          onChange={(e) => setFormData({ ...formData, address: e.target.value })}
          margin="normal"
          required
        />
        {formData.attendees.map((attendee, index) => (
          <AttendeeField
            key={index}
            attendee={attendee}
            index={index}
            onAttendeeChange={handleAttendeeChange}
            onCheckboxChange={handleCheckboxChange}
            onRemoveAttendee={handleRemoveAttendee}
          />
        ))}
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleAddAttendee} className={classes.button}>
            Add Attendee
          </Button>
          <Button type="submit" variant="contained" color="primary" className={classes.button}>
            Submit
          </Button>
        </Box>
      </form>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {
            dialogMessage.split('\n').map((line, index) => (
              <React.Fragment key={index}>
              {line}
              <br />
              </React.Fragment>
            ))
          }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    <RSVPSearch />
    </Container>
  );
};

export default RSVPForm;
