import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: 'url(https://storage.chicagoariranglionsclub.com/home/objective_1900_1200.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12),
      paddingRight: 0,
    },
  },
  button: {
    backgroundColor: "#F9C910",
    fontSize: 19,
    lineHeight: "20px",
    color: "#000000",
    letterSpacing: 0,
    display: "inline-block",
    textAlign: "center",
    textDecoration: "none",
    padding: theme.spacing(1, 2),
    '&:hover': {
      backgroundColor: "#FFD700",
    },
  },
}));

export default function MainFeaturedPost(props) {
  const classes = useStyles();
  const post = {
    title: 'Our Purpose',
    description: "",
    image: 'https://storage.chicagoariranglionsclub.com/home/objective_1900_1200.jpg',
    imgText: 'main image description',
    linkText: 'Continue reading…',
  };

  return (
    <Paper elevation={0} square className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${post.image})` }}>
      {/* Increase the priority of the hero background image */}
      {<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />}
      <div className={classes.overlay} />
      <Grid container>
        <Grid item md={6}>
          <div className={classes.mainFeaturedPostContent}>
            <Typography component="h1" variant="h3" color="inherit" gutterBottom>
              {post.title}
            </Typography>
            <Typography variant="h5" color="inherit" paragraph>
              TO CREATE and foster a spirit of understanding among the peoples of the world.
            </Typography>
            <Typography variant="h5" color="inherit" paragraph>
              TO PROMOTE the principles of good government and good citizenship.
            </Typography>
            <Typography variant="h5" color="inherit" paragraph>
              TO TAKE an active interest in the civic, cultural, social and moral welfare of the community.
            </Typography>
            <Link to="/about/objective" className={classes.button}>
              {post.linkText}
            </Link>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}

MainFeaturedPost.propTypes = {
  post: PropTypes.object,
};
