import React from "react";
import useFooterStyles from "styles/materialUI/footerStyles";
import { Box, Container, Grid, Typography } from "@material-ui/core";

const Footer = () => {
  const classes = useFooterStyles();

  return (
    <Box className={classes.footerRoot}>
      <Container maxWidth={false}>
        <Grid container direction="column" alignItems="center">
          <Grid>
            <img src="https://res.cloudinary.com/dgbtvjbev/image/upload/v1678468347/LCI_logo_white_nwpdj2.png"/>
          </Grid>
          <Grid container direction="column" alignItems="center">
            <Typography variant="subtitle1">
              {` chicagoariranglionsclub@gmail.com `}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
//${new Date().getFullYear()} |
export default Footer;