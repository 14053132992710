import {alpha, makeStyles} from "@material-ui/core/styles";

const useFooterStyles = makeStyles(theme => ({
    footerRoot:{
        width: "100%",
        height: "auto",
        backgroundColor: '#0a3dab',
        color: "white",
        paddingTop: "1rem",
        paddingBottom: "1rem",
    },
    title: {
        flexGrow: 1,
        display: "none",
        fontSize: "2.3rem",
        backgroundColor: '#0a3dab',
        color: "white",
        fontWeight: 700,
        [theme.breakpoints.up("sm")]: {
            display: "block",
            paddingBottom: 5,
        },
        [theme.breakpoints.down("sm")]: {
            display: "block",
            fontSize: "1.8rem",
            paddingBottom: 0,
        },
    }
}));

export default useFooterStyles;