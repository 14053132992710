import React from 'react';
import log from "loglevel";
import SettingsIcon from '@material-ui/icons/Settings';
import {useSelector} from "react-redux";

export default function SettingButton() {
    const addToCart = useSelector(state => state.addToCartReducer)

    log.info(`[SettingButton]: Rendering SettingButton Component`)
    return (
        <SettingsIcon>
        </SettingsIcon>
    );
};
