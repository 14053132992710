import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  cardGrid: {
    height: '50vh',
  },
}));

export default function VideoPreview() {
  const styles = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
	  <div className={styles.heroContent}>
          <Container maxWidth="sm">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Activities
            </Typography>
	  </Container>
	  </div>
      <Container className={styles.cardGrid} maxWidth="lg">
      <iframe width="100%" height="100%" src="https://www.youtube.com/embed/7w5Xu1mv6gY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </Container>
      <Container className={styles.heroContent} maxWidth="lg">
      </Container>
      </main>
    </React.Fragment>
  );
}

/*
      <Container>
      <iframe width="100%" height="100%" src="https://outlook.office365.com/owa/calendar/3eb3086ead86409183cf3710c207ef5e@chicagoariranglionsclub.com/0dd154bebee34ffc9b19ace4db04475012386614215059823316/calendar.html" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </Container>
*/