// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
    max-width: 100%;
    height: 100% !important;
    padding: 0;
    margin: 0;
}

body ::-webkit-scrollbar {
    width:0 !important;
    height: 0 !important;
}

#customDropdown {
    font-weight: bold;
}

input {
    font-size: 1.2rem !important;
}

.ui.button.googleButtonStyle {
    width: 100%;
    height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,uBAAuB;IACvB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":["html, body {\n    max-width: 100%;\n    height: 100% !important;\n    padding: 0;\n    margin: 0;\n}\n\nbody ::-webkit-scrollbar {\n    width:0 !important;\n    height: 0 !important;\n}\n\n#customDropdown {\n    font-weight: bold;\n}\n\ninput {\n    font-size: 1.2rem !important;\n}\n\n.ui.button.googleButtonStyle {\n    width: 100%;\n    height: 40px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
