import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import log from "loglevel";
import NavBar from "@/routes/navbar/navBar";
import { TabPanelList } from "@/routes/navbar/tabPanelList";
import HomeRoute from "@/routes/homeRoute";
import SignIn from "@/routes/signin/signIn";
import SignUp from "@/routes/signup/signUp";
import Product from "@/routes/product/product";
import ProductDetail from "@/routes/detail/productDetails";
import Checkout from "@/routes/checkout/checkout";
import ShoppingBag from "@/routes/shoppingBag";
import { SuccessPayment } from "@/routes/successPayment";
import { CancelPayment } from "@/routes/cancelPayment";
import { BadRequest } from "@/components/ui/error/badRequest";
import Footer from "@/routes/footer/footer";
import MembershipTable from "@/components/ui/membershipTable";
import Upload from "@/routes/upload/upload";
import AboutPage from "@/routes/aboutPage";
import BookletPage from "@/routes/bookletPage";
import HistoryPage from "@/routes/aboutPage/history";
import ObjectivePage from "@/routes/aboutPage/objective";
import AdminRoute from "@/routes/adminRoute";
import ContactPage from "@/routes/contactPage";
import DonatePage from "@/routes/donatePage";
import EventsPage from "@/routes/eventsPage";
import NewsPage from "@/routes/newsPage";
import PhotoPage from "@/routes/photoPage";
import RSVPForm from "@/routes/rsvp/RSVPForm";

const App = () => {
    log.info(`[App]: Rendering App Component`)
    const [serverError, setServerError] = useState(false);

    const setServerErrorHandler = () => {
        setServerError(true)
    }

    return (
        <Router>
            <NavBar errorHandler={setServerErrorHandler} />
            <TabPanelList />
            {!serverError && (
                <Routes>
                    <Route path="/" element={<HomeRoute />} />
                    <Route path="/admin" element={<AdminRoute />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/about/history" element={<HistoryPage />} />
                    <Route path="/about/objective" element={<ObjectivePage />} />
                    <Route path="/booklet" element={<BookletPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/donate" element={<DonatePage />} />
                    <Route path="/events" element={<EventsPage />} />
                    <Route path="/news" element={<NewsPage />} />
                    <Route path="/photo" element={<PhotoPage />} />
                    <Route path="/rsvp" element={<RSVPForm />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/shopping-bag" element={<ShoppingBag />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/products/details/shopping-bag" element={<ShoppingBag />} />
                    <Route path="/products/:details" element={<ProductDetail />} />
                    <Route path="/products" element={<Product />} />
                    <Route path="/checkout/success-payment/:id" element={<SuccessPayment />} />
                    <Route path="/checkout/cancel-payment/:id" element={<CancelPayment />} />
                    <Route path="/membership" element={<MembershipTable />} />
                    <Route path="/upload" element={<Upload />} />
                    <Route path="*" element={<BadRequest />} />
                </Routes>
            )}
            <Footer />
        </Router>
    )
}

export default App;
