import axios from 'axios';

const {
    REACT_APP_COMMON_DATA_SERVICE_PORT,
    REACT_APP_AUTHENTICATION_SERVICE_PORT,
    REACT_APP_SEARCH_SUGGESTION_SERVICE_PORT,
    REACT_APP_COMMON_DATA_SERVICE_URL,
    REACT_APP_AUTHENTICATION_SERVICE_URL,
    REACT_APP_SEARCH_SUGGESTION_SERVICE_URL
} = process.env

export const authServiceAPI = axios.create({
    baseURL: REACT_APP_AUTHENTICATION_SERVICE_URL || `https://auth.chicagoariranglionsclub.com`
})

export const commonServiceAPI = axios.create({
    baseURL: REACT_APP_COMMON_DATA_SERVICE_URL || `https://common.chicagoariranglionsclub.com`
})

/*
export const searchSuggestionServiceAPI = axios.create({
    baseURL: REACT_APP_SEARCH_SUGGESTION_SERVICE_URL || `https://chicagoariranglionsclub.com:${REACT_APP_SEARCH_SUGGESTION_SERVICE_PORT}`
})
*/
