import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import log from "loglevel";
import {
  AppBar, Toolbar, IconButton, Typography, Hidden, Grid, SwipeableDrawer, Divider, List, ListItem, Avatar
} from '@material-ui/core';
import {
  Search as SearchIcon, AccountCircle, Menu as MenuIcon, MoreVert as MoreIcon, ChevronRight as ChevronRightIcon
} from '@material-ui/icons';

import {
  getDataViaAPI, setAuthDetailsFromCookie, signOut, signOutUsingOAuth
} from '@/actions';
import { ADD_TO_CART, LOAD_TABS_DATA, SET_GOOGLE_AUTH } from "actions/types";
import useNavBarStyles from "styles/materialUI/navBarStyles";
import TabList from "./tabList";
import SettingButton from "./settingButton";
import BagButton from "./bagButton";
import SideBar from "./sideBar";
import MobileMenu from "./mobileMenu";
import { HTTPError } from "@/components/ui/error/httpError";
import { BadRequest } from "@/components/ui/error/badRequest";
import { SHOPPERS_PRODUCT_INFO_COOKIE, AUTH_DETAILS_COOKIE } from "@/constants/cookies";
import { TABS_DATA_API } from "@/constants/api_routes";
import { TABS_API_OBJECT_LEN, TAB_CONFIG } from "@/constants/constants";
import history from "@/browseHistory";

const NavBar = (props) => {
  const styles = useNavBarStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const { isSignedIn, tokenId, firstName } = useSelector(state => state.signInReducer);
  const googleAuthReducer = useSelector(state => state.googleAuthReducer);
  const tabsAPIData = useSelector(state => state.tabsDataReducer);

  useEffect(() => {
    log.info(`[NavBar]: Component did update.`);
    
    if (!googleAuthReducer.oAuth) {
      window.gapi.load('client:auth2', () => {
        window.gapi.client.init({
          clientId: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
          scope: 'profile'
        }).then(() => {
          const auth = window.gapi.auth2.getAuthInstance();
          dispatch({
            type: SET_GOOGLE_AUTH,
            payload: {
              firstName: auth.currentUser.get().getBasicProfile()?.getGivenName() || null,
              oAuth: auth
            }
          });
        }).catch(() => {
          log.error(`[Navbar] Failed to load google OAuth`);
        });
      });
    }

    if (isSignedIn === null) {
      const savedAuthDetails = Cookies.get(AUTH_DETAILS_COOKIE);
      if (savedAuthDetails) {
        props.setAuthDetailsFromCookie(JSON.parse(savedAuthDetails));
      }
    }

    if (!tabsAPIData.hasOwnProperty("data")) {
      props.getDataViaAPI(LOAD_TABS_DATA, TABS_DATA_API, null, false);
    }

    setAddToCartValuesFromCookie();
  }, [isSignedIn, tabsAPIData]);

  const setAddToCartValuesFromCookie = () => {
    let savedProductsFromCookie = Cookies.get(SHOPPERS_PRODUCT_INFO_COOKIE);
    if (savedProductsFromCookie) {
      savedProductsFromCookie = JSON.parse(savedProductsFromCookie);
      const totalQuantity = Object.values(savedProductsFromCookie.productQty)
        .reduce((total, qty) => total + parseInt(qty), 0);
      savedProductsFromCookie.totalQuantity = totalQuantity;

      log.info(`[SettingButton] savedProductsFromCookie = ${JSON.stringify(savedProductsFromCookie)}`);

      dispatch({
        type: ADD_TO_CART,
        payload: savedProductsFromCookie
      });
    }
  };

  const changeAuthStatusHandler = () => {
    if (googleAuthReducer.isSignedInUsingOAuth) {
      props.signOutUsingOAuth(googleAuthReducer.oAuth);
    } else if (tokenId && isSignedIn) {
      props.signOut();
    } else {
      history.push("/signin");
    }
    handleMobileMenuClose();
  };

  const changePageToSettingHandler = () => {
    history.push("/setting");
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const renderIndependentElem = (eventHandler, icon, label, paddingTop) => (
    <Grid item>
      <Grid container direction="column" alignItems="center" onClick={eventHandler} style={{ cursor: 'pointer' }}>
        <Grid item style={{ height: 21, width: 21, paddingTop }}>
          {icon}
        </Grid>
        <Grid item style={{ color: "white", fontSize: "0.8rem", fontWeight: 'bold' }}>
          {label}
        </Grid>
      </Grid>
    </Grid>
  );

  if (tabsAPIData.isLoading) {
    return null;
  } else if (tabsAPIData.hasOwnProperty("data")) {
    if (Object.entries(tabsAPIData.data).length !== TABS_API_OBJECT_LEN) {
      return <BadRequest />;
    }
  } else if (tabsAPIData.hasOwnProperty("statusCode")) {
    props.errorHandler();
    return <HTTPError statusCode={tabsAPIData.statusCode} />;
  }

  const authIcon = <AccountCircle style={{ color: '#fff' }} />;
  const authLabel = isSignedIn || googleAuthReducer.isSignedInUsingOAuth ? "Sign Out" : "Sign In";
  const fName = firstName || (googleAuthReducer.isSignedInUsingOAuth && googleAuthReducer.firstName) || "S";

  return (
    <>
      <AppBar position="sticky" className={styles.appBarRoot}>
        <Toolbar styles={{ root: styles.toolBarRoot }}>
          <Grid container alignItems="center">
            <Hidden lgUp>
              <Grid item>
                <IconButton edge="start" className={styles.menuButton} color="inherit" aria-label="open drawer" onClick={() => setOpen(true)}>
                  <MenuIcon fontSize="large" />
                </IconButton>
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Link to="/">
                <img src="https://upload.wikimedia.org/wikipedia/en/thumb/5/5b/Lions_Clubs_International_logo.svg/1024px-Lions_Clubs_International_logo.svg.png" height="70" style={{ marginRight: '5px' }} />
              </Link>
            </Hidden>
            <Grid item>
              <Link to="/">
                <Typography className={styles.title}>Chicago Arirang Lions Club</Typography>
              </Link>
            </Grid>
            <div className={styles.growHalf} />
            <Hidden mdDown>
              <Grid item xs={5}>
                <TabList />
              </Grid>
              <div className={styles.growHalf} />
            </Hidden>
            <Hidden mdDown>
              <div className={styles.growQuarter} />
              {renderIndependentElem(changeAuthStatusHandler, authIcon, authLabel, 2)}
            </Hidden>
          </Grid>
        </Toolbar>
        <SwipeableDrawer anchor="left" open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
          <div onClick={() => setOpen(false)} onKeyPress={() => setOpen(false)} role="button" tabIndex={0}>
            <IconButton><ChevronRightIcon /></IconButton>
          </div>
          <Divider />
          <List>
            {TAB_CONFIG.map((item) => (
              <ListItem key={item.label}>
                <Link color="textPrimary" variant="button" underline="none" to={`/${item.mapKey}`}>
                  {item.label}
                </Link>
              </ListItem>
            ))}
          </List>
        </SwipeableDrawer>
      </AppBar>
      <MobileMenu
        mobileMenuId="primary-search-account-menu-mobile"
        authIcon={authIcon}
        authLabel={authLabel}
        authBtnHandler={changeAuthStatusHandler}
        settingBtnHandler={changePageToSettingHandler}
        mobileMoreAnchorEl={mobileMoreAnchorEl}
        isMobileMenuOpen={isMobileMenuOpen}
        handleMobileMenuClose={handleMobileMenuClose}
      />
    </>
  );
};

export default connect(null, {
  setAuthDetailsFromCookie,
  signOut,
  signOutUsingOAuth,
  getDataViaAPI
})(NavBar);
