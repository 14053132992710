import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import './post.css';

const Post = ({ filename }) => {
  const [postContent, setPostContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log(`Fetching markdown file: ${filename}`);

    const fetchMarkdown = async () => {
      try {
        const response = await fetch(`/markdown/${filename}`);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const text = await response.text();
        console.log('Fetched content:', text);
        setPostContent(text);
      } catch (err) {
        console.error('Error fetching Markdown content:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMarkdown();
  }, [filename]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <article className="article">
      <div className="container">
        <div className="markdown">
          <ReactMarkdown
            children={postContent}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          />
        </div>
      </div>
    </article>
  );
};

export default Post;
