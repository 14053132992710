import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import President from "./president"
import Post from "@/components/ui/post"

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
}));

export default function HistoryPage() {
  const styles = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        
	  <div className={styles.heroContent}>
      <Container maxWidth="md">
        <President/>
	  </Container>
	  </div>
      </main>
    </React.Fragment>
  );
}

